<template>
  <div class="HotlineBox">
    <div class="Hotline">
      <div class="phoneBox">
        <img src="@/assets/images/phone.png" alt="" />
        <div class="phone">
          <span>{{ $t("hotLine.phone") }}</span>
          <p>010-82157368-8001</p>
        </div>
      </div>
      <router-link :to="{ name: 'contact' }" class="contact">
        {{ $t("hotLine.contact") }}
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "Hotline"
};
</script>

<style lang="scss">
.HotlineBox {
  width: 100%;
  height: 132px;
  background: rgba(5, 100, 205, 1);
  .Hotline {
    width: 1300px;
    padding: 36px 0 40px 0;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .phoneBox {
      img {
        width: 54px;
        height: 54px;
        float: left;
        margin-right: 22px;
      }
      .phone {
        float: left;
        color: rgba(255, 255, 255, 1);
        span {
          font-weight: 400;
          line-height: 22px;
        }
        p {
          font-weight: 600;
          font-size: 28px;
          line-height: 50px;
        }
      }
    }
    .contact {
      width: 160px;
      height: 48px;
      text-align: center;
      font-size: 16px;
      font-weight: 600;
      color: rgba(255, 255, 255, 1);
      line-height: 48px;
      border: 2px solid rgba(255, 255, 255, 1);

      &:hover {
        border: 4px solid rgba(255, 255, 255, 1);
        transition: border 0.4s;
      }
    }
  }
}
</style>
