<template>
  <div class="footerBox">
    <div class="bottomBox">
      <!--<div class="top">
        <div class="left footer_left">
          <img src="@/assets/images/logo2.png" alt="" />
          <img src="@/assets/images/slogan.png" alt="" />
        </div>
        <ul class="right">
          <li>
            <p class="title">{{ $t("header.subMenus.devServe") }}</p>
            <router-link :to="{ name: 'zhijuyun' }">
              {{ $t("header.subMenus.zjy") }}
            </router-link>
            <router-link :to="{ name: 'qianfanyun' }">
              {{ $t("header.subMenus.qfy") }}
            </router-link>
            <router-link :to="{ name: 'ylb' }">
              {{ $t("header.subMenus.ylb") }}
            </router-link>
          </li>
          <li>
            <p class="title">{{ $t("header.subMenus.xcp") }}</p>
            <router-link :to="{ name: 'tianqin' }">
              {{ $t("header.subMenus.tq") }}
            </router-link>
            <router-link :to="{ name: 'sheshou' }">
              {{ $t("header.subMenus.ss") }}
            </router-link>
            <router-link :to="{ name: 'linyuan' }">
              {{ $t("header.subMenus.ly") }}
            </router-link>
          </li>
          <li>
            <p class="title">{{ $t("header.subMenus.xcrgznyq") }}</p>
            <router-link :to="{ name: 'taitan' }">
              {{ $t("header.subMenus.ttfkxt") }}
            </router-link>
            <router-link :to="{ name: 'xianhou' }">
              {{ $t("header.subMenus.xhytyq") }}
            </router-link>
          </li>
          <li>
            <p class="title">{{ $t("header.subMenus.yhcpjz") }}</p>
            <router-link :to="{ name: 'baolaidian' }">
              {{ $t("header.subMenus.bld") }}
            </router-link>
            <router-link :to="{ name: 'qushouru' }">
              {{ $t("header.subMenus.qsr") }}
            </router-link>
            <router-link :to="{ name: 'xlaidian' }">
              {{ $t("header.subMenus.xld") }}
            </router-link>
          </li>

          <li>
            <p class="title">
              {{ $t("header.subMenus.szyx") }}
            </p>
            <router-link :to="{ name: 'fyl' }">
              {{ $t("header.subMenus.fylyx") }}
            </router-link> 
            <router-link :to="{ name: 'juliang' }">
              {{ $t("header.subMenus.jlyq") }}
            </router-link>
            <router-link :to="{ name: 'cili' }">
              {{ $t("header.subMenus.clyq") }}
            </router-link>
          </li>
        </ul> 
      </div>-->
      <div class="bottom">
        <div class="left">
          Copyright © 2010 - 2020 Star-media. All Right Reserved.
          <a href="https://beian.miit.gov.cn/" target="_blank">京ICP备11012669号-12</a>
        </div>
        <div class="right">
          <router-link :to="{ name: 'about' }">
            {{ $t("header.menus.about") }}
          </router-link>
          <!-- <router-link to="newsList">{{ $t("header.menus.news") }}</router-link> -->
          <router-link :to="{ name: 'contact' }">
            {{ $t("header.menus.contact") }}
          </router-link>
          <a href="http://company.zhaopin.com/CC388739527.htm" target="_blank">
            {{ $t("header.menus.join") }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer"
};
</script>
<style lang="scss">
.footerBox {
  width: 100%;
  height: 120px;
  background: rgba(252, 252, 252, 1);
  font-size: 16px;
  .bottomBox {
    width: 1300px;
    margin: auto;
    height: 20px;
    padding: 50px 0;
    .top {
      width: 100%;
      height: 220px;
      .footer_left{
        position:relative;
        left:50%;
        margin-left: -200px;
      }
      .left {
        float: left;
        width: 400px;
        img:first-child {
          width: 178px;
          height: 68px;
          float: left;
          margin-bottom: 9px;
        }
        img:last-child {
          width: 372px;
          float: left;
          height: 27px;
        }
      }
      .right {
        float: right;
        li {
          float: left;
          margin-left: 40px;
          font-weight: 400;
          color: rgba(102, 102, 102, 1);
          .title {
            margin-bottom: 20px;
            line-height: 30px;
            font-size: 500;
            color: rgba(34, 34, 34, 1);
          }
          a {
            display: block;
            text-align: left;
            line-height: 22px;
            margin-bottom: 18px;
            color: rgba(102, 102, 102, 1);
            &:hover {
              color: #007aff;
            }
          }
        }
      }
    }
    .bottom {
      width: 100%;
      height: 30px;
      color: rgba(102, 102, 102, 1);
      .left {
        float: left;
        a{
          color: rgba(102, 102, 102, 1);
          &:hover{
            color: #007aff;
          }
        }
      }
      .right {
        float: right;
        a {
          text-decoration: none;
          color: rgba(102, 102, 102, 1);
          margin-right: 35px;
          &:hover {
            color: #007aff;
          }
        }
        a:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
</style>
