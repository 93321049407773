<template>
  <div class="headerBox">
    <div class="header" :style="headStyle">
      <div class="top">
        <div class="logo">
          <img src="@/assets/images/logo.png" alt="" />
        </div>
        <div class="nav">
          <router-link :to="{ name: 'home' }" class="nava">
            {{ $t("header.menus.home") }}
          </router-link>
          <!-- <div
            class="nava1"
            @mouseout="outStyle('out')"
            @mouseover="outStyle('over')"
          >
            {{ $t("header.menus.services") }}
            <Nav v-show="navFlag"></Nav>
          </div> -->
          <router-link :to="{ name: 'about' }" class="nava">
            {{ $t("header.menus.about") }}
          </router-link>
          <!-- <router-link to="newsList" class="nava">
            {{ $t("header.menus.news") }}
          </router-link> -->
          <router-link :to="{ name: 'contact' }" class="nava" style="margin-right:0;">
            {{ $t("header.menus.contact") }}
          </router-link>
        </div>
        <!-- <div class="lan">
          <span @click="changeLang('zh')" :class="lang == 'zh' ? 'check' : ''">
            中
          </span>
          <span>/</span>
          <span @click="changeLang('en')" :class="lang == 'en' ? 'check' : ''">
            EN
          </span>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
// import Nav from "./nav.vue";
import cookieBus from '../../server/cookieBus.js';
import cookie from "js-cookie";
export default {
  name: "Header",
  props: ["title"],
  inject: ["reload"],
  // components: {
  //   Nav
  // },
  head() {
    return {
      "title": this.$t(`metaInfoData.${this.title}.title`),
      "keywords": this.$t(`metaInfoData.${this.title}.keywords`),
      "description": this.$t(`metaInfoData.${this.title}.description`)
    }
  },
  data() {
    return {
      navFlag: false,
      headStyle: "background: rgba(5, 100, 205, 0)",
      // lang: this.$i18n.locale
      lang:"zh"
    };
  },
  mounted() {
    if (typeof window!=undefined) {
      const { WOW } = require("wowjs");
      require('animate.css')
      new WOW().init();
      this.handleScroll()
    }
    // 监听（绑定）滚轮 滚动事件
    window.addEventListener("beforeunload", this.fn);
    window.addEventListener("scroll", this.handleScroll, true);
  },
  methods: {
    outStyle: function(type) {
      if (type == "over") {
        this.navFlag = true;
      } else {
        this.navFlag = false;
      }
    },
    handleScroll: function() {
      // 页面滚动距顶部距离
      const scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (scrollTop > 200) {
        this.headStyle = "background: rgba(5, 100, 205, 1)";
      } else {
        this.headStyle = "background: rgba(5, 100, 205, 0)";
      }
    },
    // 让页面回到顶部
    fn: function(){
      window.scrollTo(0,0);
    },
    // 中英文切换
    // changeLang(type) {
    //    console.log(this.$route.path)
    //   if (type == "en") {
    //     this.$i18n.locale = "en";
    //     this.lang = "en";
    //     cookie.set("lang", "en");
        
    //   } else if (type == "zh") {
    //     this.$i18n.locale = "zh";
    //     this.lang = "zh";
    //     cookie.set("lang", "zh");
    //   }
    //   this.$nextTick(() => {
    //     this.reload();
    //     window.location.reload();
    //   });
    // }
  },
  created() {
    if (this.title == "") {
      this.headStyle = "background: rgba(5, 100, 205, 1)";
    }
  },
  beforeCreate() {
    if(cookieBus.$cookie!=undefined){
      this.$i18n.locale=cookieBus.$cookie;
    }else if(cookie.get("language")!=undefined){
      if(cookie.get("lang")!=undefined){
        this.$i18n.locale=cookie.get("lang");
      }else{
        this.$i18n.locale=cookie.get("language");
      }
    }
  },
};
</script>
<style lang="scss">
.headerBox {
  width: 100%;
  .header {
    width: 100%;
    height: 78px;
    background: rgba(5, 100, 205, 0);
    font-size: 16px;
    position: fixed;
    z-index: 100;
    top: 0;
    transition: height 0.4s, top 0.4s, padding 0.6s;
    .top {
      width: 1300px;
      margin: auto;
      height: 52px;
      padding: 14px 0 24px 0;
      .logo {
        float: left;
        img {
          width: 137px;
          height: 52px;
        }
      }
      .nav {
        float: right;
        margin-left: 309px;
        line-height: 52px;
        cursor: pointer;
        a {
          text-decoration: none;
          // font-weight: 600;
        }
        &:hover {
          .nava,
          .nava1 {
            color: rgba(255, 255, 255, 0.5);
          }
        }
        .nava {
          margin-right: 55px;
          height: 52px;
          display: inline-block;
          color: rgba(255, 255, 255, 1);
          &:hover {
            color: rgba(255, 255, 255, 1);
          }
        }
        .nava1 {
          display: inline-block;
          position: relative;
          margin-right: 55px;
          // font-weight: 600;
          color: rgba(255, 255, 255, 1);
          &:hover {
            color: rgba(255, 255, 255, 1);
          }
        }
      }
      .lan {
        width: 10%;
        float: right;
        line-height: 52px;
        text-align: right;
        color: rgba(255, 255, 255, 0.5);
        cursor: pointer;
        &:hover {
          span {
            color: rgba(255, 255, 255, 0.5);
          }
        }
        span:hover {
          color: rgba(255, 255, 255, 1);
        }
        .check {
          color: rgba(255, 255, 255, 1);
        }
      }
    }
    &:hover {
      padding-top: 10px;
      padding-bottom: 10px;
      transition: height 0.4s, top 0.4s, padding 0.6s;
    }
  }
}
</style>